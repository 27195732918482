import * as React from "react";
import styled, { css } from "styled-components";
import { AppTitle, AppText, AppLogo } from "../../ui";
import { Link } from "gatsby";
import { mobileSize, laptopSize } from "../../../util/variables";
import { AppLink } from "../../ui/AppLink";
import { web_acq_landing_page_click } from "../../../services/mixpanel/acquisition-events";

import { HeroT } from "../../../templates/CustomLandingTemplate";
import { getStorageURL } from "../../../util/misc";

import HeroDesktopB from "../../../content/img/homepage/hero-image-desktop_b.webp";
import HeroMobileB from "../../../content/img/homepage/hero-image-mobile_b.webp";

interface HeroSegmentI {
  hero: HeroT;
}

const HeroSegment: React.FC<HeroSegmentI> = ({ hero }) => {
  const { desktopFileName, fileAlt, mobileFileName, title } = hero;
  const desktopUrl = desktopFileName ? getStorageURL(desktopFileName) : HeroDesktopB;
  const mobileUrl = mobileFileName ? getStorageURL(mobileFileName) : HeroMobileB;

  return (
    <HeroContainer>
      <Wrapper>
        <HeroContent>
          <LogoLink to="/">
            <AppLogo size="x-large" />
          </LogoLink>
          <LargeHeading>{title}</LargeHeading>
          <AppText style={{ marginBottom: 40 }}>
            Join millions of people learning on Imprint
          </AppText>
          <AppLink to="/onboarding/questions" onClick={() => web_acq_landing_page_click("hero")}>
            Get Started
          </AppLink>
        </HeroContent>
        <HeroImgDiv defaultDesktop={!desktopFileName} defaultMobile={!mobileFileName}>
          <picture style={{ objectFit: "contain" }}>
            {/* media doesn't work with template literals */}
            <source srcSet={mobileUrl} media="(max-width: 768px)" />
            <source srcSet={desktopUrl} media="(min-width: 768px)" />
            <img src={desktopUrl} alt={fileAlt} style={{ width: "100%" }} />
          </picture>
        </HeroImgDiv>
      </Wrapper>
    </HeroContainer>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;

  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  @media ${mobileSize} {
    flex-direction: column;
  }
`;

const HeroImgDiv = styled.div<{ defaultDesktop: boolean; defaultMobile: boolean }>`
  ${({ defaultDesktop }) =>
    defaultDesktop
      ? css`
          min-height: 100%;
          max-height: 100%;
          z-index: 0;
          img {
            object-fit: contain;
            max-height: 100%;
            object-position: bottom right;
          }
        `
      : css`
          aspect-ratio: 562/604;
        `}

  @media ${mobileSize} {
    position: static;
    ${({ defaultDesktop }) =>
      defaultDesktop
        ? css`
            aspect-ratio: 375/375;
          `
        : css`
            aspect-ratio: 375/220;
          `}
  }
`;

const LargeHeading = styled(AppTitle as any)`
  font-size: 56px;
  line-height: 70px;
  margin-bottom: 24px;
  @media ${laptopSize} {
    font-size: 36px;
    line-height: normal;
  }
  @media ${mobileSize} {
    font-size: 36px;
    line-height: 45px;
    /* max-width: 75%; */
  }
`;

const HeroContainer = styled.div`
  background-color: #fafafa;
  background-size: cover;
  overflow: hidden;
  position: relative;
  height: 600px;
  width: 100%;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  @media ${mobileSize} {
    height: auto;
  }
`;

const LogoLink = styled(Link)`
  margin-bottom: 48px;
  @media ${mobileSize} {
    margin-bottom: 20px;
    width: 100px;
  }
`;

const HeroContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  padding: 58px 60px 40px 60px;
  width: 60%;
  z-index: 2;

  @media ${laptopSize} {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media ${mobileSize} {
    width: 100%;
    padding-top: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export { HeroSegment };
